export type CashflowCategorySide = 'inflow' | 'outflow';
export const cashflowCategorySides = ['inflow', 'outflow'] as CashflowCategorySide[];

export interface CashflowCategory {
  colorKey: string;
  icon: string;
  id: string;
  name: string | null;
  nameKey: string | null;
}

export interface CashflowSearchCategory extends CashflowCategory {
  type: CashflowCategorySide;
}

export interface CashflowParentCategory extends CashflowCategory {
  subcategories: CashflowCategory[];
}

export interface CashflowCategories {
  inflow: CashflowParentCategory[];
  outflow: CashflowParentCategory[];
}
