import type {
  CashflowCategories,
  CashflowParentCategory,
} from 'qonto/react/models/cash-flow-category';

export function applyParentIcons(
  flowCategories: CashflowParentCategory[]
): CashflowParentCategory[] {
  return [...flowCategories].map((category: CashflowParentCategory) => {
    if (category.subcategories.length) {
      category.subcategories = category.subcategories.map(subcategory => ({
        ...subcategory,
        icon: category.icon,
      }));
    }

    return category;
  });
}

export function applyCategoriesIcons(categories: CashflowCategories): CashflowCategories {
  const categoriesWithIcons = {
    inflow: applyParentIcons(categories.inflow),
    outflow: applyParentIcons(categories.outflow),
  };

  return categoriesWithIcons;
}
